import React from 'react'
import Layout from '../modules/layout/Layout'
import BackgroundImg from '../img/background.svg'
import logo from '../img/logo-large.svg'
import Button from '../components/button/Button'

const NotFoundPage = () => (
  <Layout>
    <div className={'text-center no-layout'} style={{ backgroundImage: `url(${BackgroundImg})` }}>
      <img src={logo} alt={'villagusto logo'} />
      <h1>ci dispiace!</h1>
      <h2>Etwas hat leider nicht geklappt <span role={'img'} aria-label='sad face'>&#128533;</span></h2>
      <div>
        <Button text={'Zurück zur Startseite'} filled={true} to={'/'} />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
